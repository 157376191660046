import React from 'react';
import {Button, Col, DatePicker, Input, Row, Select, TimePicker} from "antd";
import moment from "moment-timezone";
import queryString from "query-string";
import _ from 'lodash';
import 'antd/dist/antd.css';

import tzlist from './tzlist'
import {ArrowDownOutlined} from "@ant-design/icons";
import TimeBar from "./TimeBar";
import Copy from "./Copy";

const {Option} = Select;

class App extends React.Component {

    constructor(props) {
        super(props);
        let tz = moment.tz.guess();
        this.state = {
            unixTimestamp: moment().unix(),
            tz: tz,
            multipleTz: [tz]
        }
    }

    componentDidMount() {

        let params = queryString.parse(window.location.search);
        let unixTimestamp = moment().unix();
        let tz = moment.tz.guess();
        let multipleTz = [tz];

        if (params && params.unixTimestamp && Number.isInteger(Number(params.unixTimestamp)) && Number(params.unixTimestamp) > -999999999999 && Number(params.unixTimestamp) < 999999999999) {
            unixTimestamp = Number(params.unixTimestamp)
        }
        if (params && params.timeZone) {
            tz = params.timeZone;
            multipleTz = [params.timeZone];
        }

        if (params && params.multipleTz) {
            if (Array.isArray(params.multipleTz)) {
                _.merge(multipleTz, params.multipleTz);
            } else {
                _.merge(multipleTz, [params.multipleTz]);
            }
        }

        this.setState({
            unixTimestamp: unixTimestamp,
            tz: tz,
            multipleTz: multipleTz
        });

        this.updateUnixTimestampOnQuery(unixTimestamp);
        this.updateTzOnQuery(tz);
        this.updateMultipleTzOnQuery(multipleTz);

        window.onpopstate = (e) => {
            if (e.state && e.state.type === 'custom') {
                this.setState(e.state.state);
            }
        }
    }

    changeUnixTimestamp = (e) => {
        let value = Number(e.target.value);
        if (!Number.isInteger(value) || value < -999999999999 || value > 999999999999) return;
        this.setState({
            unixTimestamp: value
        });
        this.updateUnixTimestampOnQuery(value);
    };

    updateUnixTimestampOnQuery = (epoch) => {
        let params = queryString.parse(window.location.search);
        delete params.unixTimestamp;
        _.merge(params, {unixTimestamp: epoch});
        window.history.pushState({type: 'custom', state: this.state}, null, "?" + queryString.stringify(params, {strict: true}));
    };

    updateTzOnQuery = (tz) => {
        let params = queryString.parse(window.location.search);
        delete params.timeZone;
        _.merge(params, {timeZone: tz});
        window.history.pushState({type: 'custom', state: this.state}, null, "?" + queryString.stringify(params, {strict: true}));
    };


    changeTz = (value) => {
        let dateStr = moment.unix(this.state.unixTimestamp).tz(this.state.tz).format("YYYY-MM-DD HH:mm");
        let epoch = moment.tz(dateStr, value).unix();

        let newMultipleTz = this.state.multipleTz.filter(tz => tz !== value);
        newMultipleTz.unshift(value);

        this.setState({
            tz: value,
            unixTimestamp: epoch,
            multipleTz: newMultipleTz
        });
        this.updateTzOnQuery(value);
        this.updateUnixTimestampOnQuery(epoch);
        this.updateMultipleTzOnQuery(newMultipleTz);
    };

    updateWithHere = () => {
        let dateStr = moment.unix(this.state.unixTimestamp).tz(this.state.tz).format("YYYY-MM-DD HH:mm");
        let tz = moment.tz.guess();
        let epoch = moment.tz(dateStr, tz).unix();

        let newMultipleTz = this.state.multipleTz.filter(o => o !== tz);
        newMultipleTz.unshift(tz);


        this.setState({
            tz: tz,
            unixTimestamp: epoch,
            multipleTz: newMultipleTz
        });
        this.updateTzOnQuery(tz);
        this.updateUnixTimestampOnQuery(epoch);
    };

    changeTimePicker = (date, string) => {
        this.setState({
            unixTimestamp: date.unix()
        });
        this.updateUnixTimestampOnQuery(date.unix());
    };

    updateWithNow = () => {
        let epoch = moment().unix();
        this.setState({
            unixTimestamp: epoch
        });
        this.updateUnixTimestampOnQuery(epoch);
    };

    updateMultipleTzOnQuery = (multipleTz) => {
        let params = queryString.parse(window.location.search);
        delete params.multipleTz;
        _.merge(params, {multipleTz: multipleTz});
        window.history.pushState({type: 'custom', state: this.state}, null, "?" + queryString.stringify(params, {strict: true}));
    };

    selectMultipleTz = (value) => {
        this.setState({
            multipleTz: value
        });
        this.updateMultipleTzOnQuery(value)
    };

    addTime = (val) => {
        let epoch = this.state.unixTimestamp + val;
        this.setState({
            unixTimestamp: epoch
        });
        this.updateUnixTimestampOnQuery(epoch);
    };


    render() {
        // console.log(this.state);
        const tzOptions = tzlist.map((tz) => {
            return (<Option key={tz} value={tz}>{`${moment.unix(this.state.unixTimestamp).tz(tz).format('Z z')} : ${tz}`}</Option>)
        });
        return (<div>
            <Row justify="center">
                <Col>
                    <h1 style={{marginTop: 10, marginBottom: 0, textAlign: 'center'}}><a href={"/"}>Time Converter</a></h1>
                    <div style={{height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Copy/></div>
                    <div style={{border: 'solid', borderRadius: 10, padding: 20, color: 'lightgray'}}>
                        <div style={{display: 'flex', justifyContent: 'center', marginBottom: 15}}>
                            <div style={{marginRight: 6}}><Button onClick={() => {
                                this.addTime(-60 * 60 * 24)
                            }} type="primary">-1 day</Button></div>
                            <div style={{marginRight: 30}}><Button onClick={() => {
                                this.addTime(-60 * 60)
                            }} type="primary">-1 hour</Button></div>
                            <div style={{marginRight: 3}}><Button onClick={this.updateWithNow} type="primary">Now</Button></div>
                            <div style={{marginLeft: 3}}><Button onClick={this.updateWithHere} type="primary">Here</Button></div>
                            <div style={{marginLeft: 30}}><Button onClick={() => {
                                this.addTime(60 * 60)
                            }} type="primary">+1 hour</Button></div>
                            <div style={{marginLeft: 6}}><Button onClick={() => {
                                this.addTime(60 * 60 * 24)
                            }} type="primary">+1 day</Button></div>
                        </div>
                        <div style={{display: 'flex'}}>
                            <Input addonBefore="UnixTimestamp"
                                   type={'number'}
                                   style={{width: 300}}
                                   value={this.state.unixTimestamp}
                                   onChange={this.changeUnixTimestamp}/></div>
                        <div style={{height: 10}}></div>
                        <div style={{display: 'flex'}}>
                            <Select
                                value={this.state.tz}
                                showSearch
                                style={{width: 300}}
                                placeholder="Select TimeZone"
                                optionFilterProp="children"
                                onChange={this.changeTz}
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >{tzOptions}
                            </Select>
                            <DatePicker allowClear={false} onChange={this.changeTimePicker} value={moment.unix(this.state.unixTimestamp).tz(this.state.tz)}/>
                            <TimePicker allowClear={false} onChange={this.changeTimePicker} value={moment.unix(this.state.unixTimestamp).tz(this.state.tz)} format={'HH:mm'}
                            />

                        </div>
                    </div>
                    <div style={{textAlign: 'center', marginTop: 10, marginBottom: 10}}><ArrowDownOutlined style={{fontSize: 30, color: 'grey'}}/></div>
                    <div style={{marginTop: 20, marginBottom: 20}}>
                        <Select
                            mode="multiple"
                            placeholder="Select Multiple TimeZones"
                            onChange={this.selectMultipleTz}
                            style={{width: 600}}
                            value={this.state.multipleTz}
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {tzOptions}
                        </Select>
                    </div>
                    <div>
                        {this.state.multipleTz.map(tz => {
                            return (
                                <TimeBar key={tz} unixTimestamp={this.state.unixTimestamp} tz={tz}/>
                            )
                        })}
                    </div>
                </Col>
            </Row>
            <div style={{height: 150}}></div>
        </div>);
    }
}

export default App