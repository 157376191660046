import React from 'react';
import 'antd/dist/antd.css';
import moment from "moment-timezone";

class TimeBar extends React.Component {

    constructor(props) {
        super(props);
    }

    strToRGB = (str) => {
        if (!str) {
            return '#000000';
        }
        let hash = 0;
        for (let i = 0; i < str.length; i += 1) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        const c = (hash & 0x00ffffff).toString(16).toUpperCase();

        const hex = `#${'00000'.substring(0, 6 - c.length) + c}`;
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);

        return `rgba(${r},${g},${b},0.3)`;
    };


    generateBar = () => {
        let center = this.props.unixTimestamp;
        let ret = [];

        for (let i = -11; i < 0; i++) {
            ret.push(this.props.unixTimestamp + i * 60 * 60)
        }
        ret.push(center);
        for (let i = 1; i <= 12; i++) {
            ret.push(this.props.unixTimestamp + i * 60 * 60)
        }

        return ret.map(epoch => {
            let dateStr = moment.unix(epoch).tz(this.props.tz).format("YYYY/MM/DD HH");
            let hh = dateStr.substr(-2);
            let dd = dateStr.substring(0, 10);
            let dd_epoch = moment(dd.replaceAll("/", "-")).unix();
            let border = epoch === center ? 'solid 1px' : '';
            let color = this.strToRGB(dd_epoch / 2 + "");

            if (hh === '00') return (
                <div key={epoch}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: 25, height: 20, textIndent: 65}}>{dd}</div>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: color, border: border, width: 25, height: 30}}>{Number(hh)}</div>
                </div>

            );
            if (hh === '23') return (
                <div key={epoch}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: 25, height: 20, textIndent: -63}}>{dd}</div>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: color, border: border, width: 25, height: 30}}>{Number(hh)}</div>
                </div>

            );
            return (
                <div key={epoch}>
                    <div style={{width: 25, height: 20}}></div>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: color, border: border, width: 25, height: 30}}>{Number(hh)}</div>
                </div>)
        });
    };


    render() {
        if (!Number.isInteger(this.props.unixTimestamp) || this.props.unixTimestamp < -999999999999 || this.props.unixTimestamp > 999999999999) return (<div>wrong input time</div>)
        return (
            <div>
                <div style={{marginBottom: 5}}>{this.props.tz} is {moment.unix(this.props.unixTimestamp).tz(this.props.tz).format("YYYY/MM/DD HH:mm")}</div>
                <div style={{display: 'flex', marginBottom: 15}}>
                    {this.generateBar()}
                </div>
                <hr/>
            </div>
        );
    }
}

export default TimeBar