import React from 'react';
import 'antd/dist/antd.css';
import {Button} from "antd";
import {CopyToClipboard} from "react-copy-to-clipboard";

class Copy extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            copied: false
        }
    }

    copy = () => {
        this.setState({copied: true});

        setTimeout(() => {
            this.setState({copied: false});
        }, 1000);
    };


    render() {
        return (
            <div>
                <CopyToClipboard text={window.location.href}
                                 onCopy={this.copy}>
                    <Button type="primary">{this.state.copied ? 'Copied' : 'Copy this url to share the result'}</Button>
                </CopyToClipboard>
            </div>
        );
    }
}

export default Copy